<template>
  <el-form-item
      :class="[field.label && field.label.labelPosition ? 'el-form--labelPosition-' + field.label.labelPosition : 'el-form--labelPosition-top']"
      :label-width="field.label && field.label.labelWidth ? field.label.labelWidth + 'px' : ''"
      :required="field.meta && field.meta.required ? field.meta.required : false"
  >
    <template slot="label">
      <Tooltip
          :content="field.label && field.label.name ? $t(field.label.name) : ''"
          :labelTooltip="field.label.labelTooltip"
          v-if="field.label && field.label.labelTooltip"
      ></Tooltip>
      <span
          class="labelWrap"
          v-else
      >{{ field.label && field.label.name ? $t(field.label.name) : '' }}</span>
    </template>
    <el-checkbox v-model="value"></el-checkbox>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </el-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';

export default {
  name: 'CreateOrUpdate_checkbox',
  mixins: [abstractForm],
};
</script>

<style scoped>

</style>
